export const REVALIDATE_PRODUCTS_ALL = 5 * 60;
export const REVALIDATE_NEWS_ALL = 5 * 60;
export const REVALIDATE_PRODUCTS_FEATURED = 5 * 60;
export const REVALIDATE_PRODUCT = 5 * 60;
export const REVALIDATE_NEWS_PRODUCT = 5 * 60;
export const REVALIDATE_INVESTORS = 5 * 60;
export const REVALIDATE_ARTISTS_ALL = 5 * 60;
export const REVALIDATE_ARTIST_PRODUCTS = 5 * 60;
export const REVALIDATE_ARTIST = 5 * 60;
export const REVALIDATE_USER_NEWS = 5 * 60;
export const REVALIDATE_NEWS = 5 * 60;
export const REVALIDATE = 5 * 60;
export const LIMIT = 10;
export const OFFSET = 0;

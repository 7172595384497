import { useQuery } from '@tanstack/react-query';

import { profileKeys } from '@/entities/profile/model/query-keys';
import { newsApi } from '@/shared/api/config';

import { mapApiProductToProduct } from '../lib';

export const useGetFavorites = (userId?: string) => {
  return useQuery({
    enabled: !!userId,
    queryKey: profileKeys.profileFavourites(),
    queryFn: async () => {
      const response = await newsApi.getNewsV1UserFavoritesUserId({
        userId: userId!,
      });

      const mappedProducts = response.items.map(mapApiProductToProduct);
      return mappedProducts;
    },
  });
};

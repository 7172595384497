import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M19.57 3.94998C17.65 2.65998 15.49 2.77998 13.77 3.68998L12 8.99998H13.66C14.33 8.99998 14.81 9.64998 14.62 10.29L12.8 16.36C12.71 16.65 12.28 16.56 12.31 16.26L13 9.99998H11.33C10.67 9.99998 10.19 9.35998 10.37 8.72998L11.55 4.61998C9.70002 2.88998 6.71002 2.31998 4.27002 4.03998C2.82002 5.06998 2.00002 6.69998 2.00002 8.48998C1.99002 12.3 5.53002 15.2 10.66 19.79C11.42 20.47 12.58 20.48 13.35 19.8C18.33 15.38 22.22 12.22 21.99 8.17998C21.9 6.44998 21 4.91998 19.57 3.94998Z'
      fill='currentColor'
    />
  ),
});

const IconHeartBroken: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconHeartBroken);

import { createEvent, restore } from 'effector';

export const addFavorite = createEvent<string>();
export const setFavorite = createEvent<string[]>();
export const removeFavorite = createEvent<string>();
export const resetFavorite = createEvent();

export const $favorites = restore(setFavorite, []);

$favorites.reset(resetFavorite);

$favorites.on(addFavorite, (state: string[], payload: string) => {
  return [...state, payload];
});

$favorites.on(removeFavorite, (state: string[], payload: string) => {
  return state.filter((item) => item !== payload);
});

import { useAlert } from '@ui-kit/hooks/useAlert';
import IconHeartBroken from '@ui-kit/icons/icon-heart-broken';
import IconHeartFill from '@ui-kit/icons/icon-heart-fill';

import { FavoriteAlertDescription } from '../ui/favorite-alert-description';

export const useFavoriteAlert = (status: 'add' | 'delete') => {
  const isAdd = status == 'add';
  const icon = isAdd ? (
    <IconHeartFill color='text&icon.tx-accent' />
  ) : (
    <IconHeartBroken color='text&icon.tx-accent' />
  );
  const showSuccessAlert = useAlert({ status: 'success' });
  const showErrorAlert = useAlert({ status: 'error' });

  return (isSuccess: boolean) => {
    const description = (
      <FavoriteAlertDescription
        isSuccess={isSuccess}
        isAdded={isAdd}
      />
    );

    if (isSuccess) {
      return showSuccessAlert({ description, icon });
    }

    return showErrorAlert({ description, icon });
  };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { productsKeys } from '@/entities/product/model/query-keys';
import { profileKeys } from '@/entities/profile/model/query-keys';
import { newsApi } from '@/shared/api/config';

type TCallbacks = {
  onSuccess?: (productId: string) => void;
  onError?: (productId: string) => void;
  onMutate?: (productId: string) => void;
};

export const usePostFavorite = (options?: TCallbacks) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: (productId: string) =>
      newsApi.postNewsV1UserFavorites({
        postDeleteUserFavoriteRequest: { productId },
      }),
    onSuccess: (_, productId) => {
      query.invalidateQueries({ queryKey: productsKeys.all });
      query.invalidateQueries({ queryKey: profileKeys.profileFavourites() });
      options?.onSuccess?.(productId);
    },
    onError: (_, productId) => {
      options?.onError?.(productId);
    },
    onMutate: (productId) => {
      options?.onMutate?.(productId);
    },
  });
};

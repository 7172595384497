import { useMutation } from '@tanstack/react-query';

import { userApi } from '@/shared/api/config';

export const useValidateNickname = () => {
  return useMutation({
    mutationFn: (nickname: string) =>
      userApi.getUsersV1NicknameValidate({
        nickname,
      }),
  });
};

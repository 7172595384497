import { Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

type TProps = {
  isSuccess: boolean;
  isAdded: boolean;
};

export const FavoriteAlertDescription = ({
  isAdded = false,
  isSuccess,
}: TProps) => {
  const t = useTranslations();
  if (!isSuccess) {
    return (
      <Text textStyle='text-regular-lg'>
        {t.rich('FavoriteAlertDescription.error', {
          kind: isAdded ? 'added' : 'deleted',
          color: (chunks) => (
            <Text
              color='text&icon.tx-accent'
              as='span'
            >
              {chunks}
            </Text>
          ),
        })}
      </Text>
    );
  }

  return (
    <Text textStyle='text-regular-lg'>
      {t.rich('FavoriteAlertDescription.success', {
        kind: isAdded ? 'added' : 'deleted',
        color: (chunks) => (
          <Text
            color='text&icon.tx-accent'
            as='span'
          >
            {chunks}
          </Text>
        ),
      })}
    </Text>
  );
};

import {
  QueryClient,
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { Session } from 'next-auth';

import { profileKeys } from '@/entities/profile/model/query-keys';
import {
  GetUserProfile200,
  GetUsersV1ProfileError,
} from '@/shared/api/codegen/demuzo';
import { userApi } from '@/shared/api/config';
import { makeHeadersWithAuthorization } from '@/shared/utils/make-headers-with-authorization';

export const prefetchProfile = async (
  queryClient: QueryClient,
  session: Session,
) => {
  const headers = makeHeadersWithAuthorization(session);
  await queryClient.prefetchQuery({
    retry: false,
    queryKey: profileKeys.profile,
    queryFn: () => userApi.getUsersV1Profile({ headers }),
  });
  const query = queryClient.getQueryState(profileKeys.profile);
  if (query?.error !== null) {
    throw query?.error;
  }
};

export const getProfileConfig = queryOptions<
  GetUserProfile200,
  GetUsersV1ProfileError
>({
  queryKey: profileKeys.profile,
  retry: (failureCount, error) => {
    if (error.code === 'NOT_FOUND') {
      return false;
    }

    return failureCount < 2;
  },
  queryFn: () => userApi.getUsersV1Profile(),
});

export const useGetProfile = (enabled: boolean = true) =>
  useQuery({
    enabled: enabled,
    ...getProfileConfig,
  });

export const useGetProfileSuspense = () => useSuspenseQuery(getProfileConfig);

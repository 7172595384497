import { useUnit } from 'effector-react';
import { signIn, useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { useGetFavorites, useGetProfile } from '@/entities/profile';
import { useDeleteFavorite, usePostFavorite } from '@/entities/user';

import { useFavoriteAlert } from './use-favorite-alert';
import { $favorites, addFavorite, removeFavorite, setFavorite } from '../model';

export const useFavorites = () => {
  const session = useSession();
  const isAuth = session.status === 'authenticated';
  const profile = useGetProfile(isAuth);
  const { data: favoritesData } = useGetFavorites(profile.data?.id);
  const favorites = useUnit($favorites);
  useEffect(() => {
    if (favorites.length === 0 && favoritesData && favoritesData.length !== 0) {
      setFavorite(favoritesData?.map((item) => item.id));
    }
  }, [favorites.length, favoritesData, favoritesData?.length]);
  const showAddAlert = useFavoriteAlert('add');
  const showRemoveAlert = useFavoriteAlert('delete');
  const addToFavorite = usePostFavorite({
    onSuccess: () => showAddAlert(true),
    onError: (productId) => {
      showAddAlert(false);
      removeFavorite(productId);
    },
    onMutate: (productId) => addFavorite(productId),
  });
  const removeFromFavorite = useDeleteFavorite({
    onSuccess: () => showRemoveAlert(true),
    onError: (productId) => {
      showRemoveAlert(false);
      addFavorite(productId);
    },
    onMutate: (productId) => removeFavorite(productId),
  });
  const handleToggle = async (productId: string) => {
    if (!isAuth) {
      await signIn('keycloak');
      return;
    }
    if (favorites.includes(productId)) {
      removeFromFavorite.mutate(productId);
      return;
    }
    addToFavorite.mutate(productId);
  };

  return {
    favorites,
    addToFavorite,
    removeFromFavorite,
    setFavorite,
    handleToggle,
  };
};
